import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Grid from "../../components/grid"
import SEO from "../../components/seo"
import Img from "gatsby-image"
import classNames from "classnames"

import variables from "../../styles/components/grid.module.scss"
import styles from "../../styles/pages/berenson-advisory/results.module.scss"
import pageStyles from "../../styles/pages/page-styles.module.scss"

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children

const Result = ({
  description,
  companyLogo,
  textHeight,
  bottomLogo,
  bottomLogoText,
  articleLink,
}) => {
  return (
    <ConditionalWrapper
      condition={articleLink}
      wrapper={children => (
        <a
          className={styles.resultLink}
          rel="noopener noreferrer"
          target="_blank"
          href={articleLink}
        >
          {children}
        </a>
      )}
    >
      <div className={styles.resultCard}>
        <div className={classNames(styles.imageContainer, styles.top)}>
          {companyLogo && (
            <Img
              className={styles.image}
              fluid={companyLogo.fluid}
              alt={companyLogo.description}
            />
          )}
        </div>
        {bottomLogo && (
          <div className={styles.descriptionContainer}>
            <div className={styles.description}>
              <p className="resultDescription">{description.description}</p>
            </div>
          </div>
        )}
        <div className={classNames(styles.imageContainer, styles.bottom)}>
          {bottomLogoText && (
            <h6 className={styles.bottomLogoText}>{bottomLogoText}</h6>
          )}
          {bottomLogo && (
            <Img
              className={styles.image}
              fluid={bottomLogo.fluid}
              alt={bottomLogo.description}
            />
          )}
        </div>
      </div>
    </ConditionalWrapper>
  )
}

class ResultsPage extends React.Component {
  constructor(props) {
    super(props)

    this.numCols = null
    this.state = {
      textHeights: [],
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.onResize)
    this.onResize()
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize)
  }

  // used to set the text height of each individual row
  // of results
  getMaxHeightOfResultsByRow = className => {
    let elems = document.getElementsByClassName(className)
    let numRows = Math.ceil(elems.length / this.numCols)
    let newMaxHeights = []
    for (let i = 0; i < numRows; i++) {
      let maxHeight = 0
      for (let j = 0; j < this.numCols; j++) {
        let elemIndex = i * this.numCols + j
        if (elems.length <= elemIndex) continue
        maxHeight = Math.max(
          elems[i * this.numCols + j].offsetHeight,
          maxHeight
        )
      }
      newMaxHeights.push(maxHeight)
    }
    return newMaxHeights
  }

  getNumCols = () => {
    let { minWidthTwoCol, minWidthThreeCol, minWidthFourCol } = variables
    if (window.innerWidth < parseInt(minWidthTwoCol)) {
      this.numCols = 1
    } else if (window.innerWidth < parseInt(minWidthThreeCol)) {
      this.numCols = 2
    } else if (window.innerWidth < parseInt(minWidthFourCol)) {
      this.numCols = 3
    } else {
      this.numCols = 4
    }
  }

  onResize = () => {
    this.getNumCols()
    let newTextHeights = this.getMaxHeightOfResultsByRow("resultDescription")
    this.setState({
      textHeights: newTextHeights,
    })
  }

  render() {
    let results = this.props.data.contentfulResultList.resultList
    results = results.map((result, index) => {
      let textHeight =
        this.numCols === null
          ? 20 // placeholder this shouldn't matter
          : this.state.textHeights[Math.floor(index / this.numCols)]
      return <Result textHeight={textHeight} key={result.id} {...result} />
    })
    return (
      <Layout location={this.props.location.pathname}>
        <SEO title="Results" />
        {/*<BackgroundLines yPosition={460} />*/}
        <div className={pageStyles.pageContentContainer}>
          <h2 className={pageStyles.pageTitle}>Results</h2>
          <div className={styles.gridContainer}>
            <Grid maxColumns={6}>{results}</Grid>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ResultsPage

export const portfolioCompanyQuery = graphql`
  query {
    contentfulResultList {
      resultList {
        description {
          description
        }
        id
        companyLogo {
          description
          fluid(maxHeight: 100, maxWidth: 230, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        bottomLogo {
          description
          fluid(maxHeight: 100, maxWidth: 230, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        bottomLogoText
        articleLink
      }
    }
  }
`
